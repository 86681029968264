import { gapi } from 'gapi-script';
import { googleAuthGenericError } from './constants';

export const initGoogleSignIn = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return new Promise((resolve, reject) => {
    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id: clientId,
        })
        .then(() => {
          resolve(gapi.auth2.getAuthInstance());
        })
        .catch(error => {
          reject(error);
        });
    });
  });
};

export const getUserInformation = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return new Promise((resolve, reject) => {
    gapi.load('auth2', () => {
      gapi.auth2
        .init({
          client_id: clientId,
        })
        .then(auth2 => {
          resolve(auth2);
        })
        .catch(error => {
          reject(error);
        });
    });
  });
};

export const handleGoogleSignUpAndSignIn = async () => {
  const auth2 = await initGoogleSignIn();

  if (!auth2) {
    console.log('GoogleAuth instance not initialized');
    return {
      success: false,
      errorMessage: googleAuthGenericError,
    };
  }

  try {
    await auth2.signIn({
      scope: 'profile email',
      ux_mode: 'redirect',
      redirect_uri: `${window.location.origin}/auth/google-callback`,
    });
  } catch (error) {
    console.log('Google Sign-In error', error);
    return {
      success: false,
      errorMessage: googleAuthGenericError,
    };
  }
};

export const getUserInformationFromGoogle = async () => {
  try {
    const auth2 = await getUserInformation();
    const googleUser = auth2.currentUser?.get();

    if (googleUser) {
      const idToken = googleUser.getAuthResponse().id_token;
      const userInformation = {
        firstName: googleUser.getBasicProfile().getGivenName(),
        lastName: googleUser.getBasicProfile().getFamilyName(),
        email: googleUser.getBasicProfile().getEmail(),
      };

      return { success: true, data: userInformation, idToken };
    } else {
      return { success: false, error: 'No user is signed in' };
    }
  } catch (error) {
    return {
      success: false,
      error: googleAuthGenericError,
    };
  }
};
